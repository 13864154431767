import React, { useEffect, useState } from "react";
import styles from "./permission.module.scss";
import DatePicker from "react-datepicker"
import stylesGeneric from "../../styles/generics.module.scss";
import { getCookie } from "../../methods/cookie";
import {useNavigate, useParams} from "react-router-dom";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {AppConfig, AppConfigs} from "../../interfaces/AppConfig";
import {fetchDesign, fetchWrapper, postDesign} from "../../methods/connector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGear, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {SaveButton} from "../../components/SaveButton/SaveButton";
import {Background} from "../../components/background/Background";
import {getConstants} from "../../methods/getEvents";
import {getDeepKeys, logout, openTab} from "../../methods/helpers";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";
import {validateDesign} from "../../methods/validateConfig";

const _ = require('lodash');

// list all stages for the given config, with a button to open and edit them
export const PermissionScreen = () => {
  const routeParams = useParams();
  const designTag = routeParams.designTag;
  const stageName = routeParams.stageName;

  const navigate = useNavigate();
  const jwt = getCookie('jwt');
  const [designs, setDesigns] = useState<AppConfigs>();
  const [design, setDesign] = useState<AppConfig>();
  const [pageNames, setPageNames] = useState<string[]>([]);
  const [pageRename, setPageRename] = useState<string>(stageName ?? "");
  const [timeoutView , setTimeoutView] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [addString, setAddString] = useState<string>('');



  const getKeys = () => {
    const designsToSearch = design ? [design] : Object.values(designs?.configs ?? {});
    return [...designsToSearch
      .map((des: any) => getDeepKeys(des))
      .flat()
      .filter((v,i,a) =>
      a.indexOf(v) === i && isNaN(Number(v))
    )
      .filter((key) => !(key.startsWith('permission'))),
      ...(design ? [] : Object.keys(designs?.settings ?? {}).map((k) => `settings.${k}`))
    ];
  }

  const allKeys = getKeys();

  const addEntry = async (newKey: string) => {
    if (!designs) {
      return;
    }
    if (newKey === "") {
      return;
    }
    const d = _.cloneDeep(design ?? designs);

    if (d?.permission?.[newKey]) {
      return;
    }

    if (!(d?.permission)){
      d.permission = {};
    }
    d.permission[newKey] = {};
    if (design) {setDesign(d)} else {setDesigns(d)}
  }

  const deleteEntry = async (key: string) => {
    if (!designs) {
      return;
    }
    const d = _.cloneDeep(design ?? designs);

    if (!(d?.permission?.[key])) {
      return;
    }
    delete d.permission[key];
    if (design) {setDesign(d)} else {setDesigns(d)}
  }

  const setValue = (key: string, subKey: string, value: number) => {
    if (isNaN(value)) {
      return;
    }
    value = Math.max(0, Math.min(value, 999));

    const d = _.cloneDeep(design ?? designs);

    if (!(d?.permission?.[key])) {
      return;
    }

    d.permission[key][subKey] = value;
    if (design) {setDesign(d)} else {setDesigns(d)}

  }

  const postConfig = async () => {
    if (!designs || timeoutView) {
      return;
    }
    if ((designs.userPermission ?? 0) < 400) {
      window.alert('Insufficient permission level.');
      return;
    }
    if (stageName) {
      if (!design) {
        return;
      }
      // const {status, errorMsg} = validateDesign(design, pageNames);
      // if (!status) {
      //   window.alert(errorMsg);
      //   return;
      // }
      designs!.configs[stageName] = design;
    }

    setLoading(true);
    const data = await postDesign(designs, jwt)
    setLoading(false);
    if (data.status !== 200) {
      return;
    }
    setTimeoutView(true);
    setTimeout(() => {setTimeoutView(false)}, 1000);
  }

  useEffect(() => {
    const getDesigns = async() => {
      if (!designTag) {
        return;
      }
      const r = await fetchDesign(designTag, jwt);
      if (!r) {
        return;
      }
      const conf = r.configs?.[stageName ?? ''];
      const pageNames = Object.keys(r.configs);
      setDesign(conf);
      setDesigns(r);
      setPageNames(pageNames);
    }
    getDesigns();
  }, [])

  return <div className={stylesGeneric["page"]}>
    <Background/>
    {/*<HomeButtonRow/>*/}
    <div className={stylesGeneric["settings-button-row"]}>
      <div className={stylesGeneric["settings-button"]}>
        <HomeButton/>
      </div>
      <div className={stylesGeneric["settings-button"]}>
        <SaveButton onClick={postConfig}/>
      </div>
      <div className={stylesGeneric["settings-button"]}>
        <button className={stylesGeneric['generic-button-wrapper']} onClick={() => {
          setAdd(!add);
        }}>
          <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faPlus}/>
        </button>
      </div>
    </div>
    <div className={stylesGeneric["content-wrapper"]}>
      <div className={stylesGeneric["page-wrapper"]}>
        <table className={styles['table']}>
          <thead className={styles['thead']}>
          <tr className={styles['tr-head']}>
            <td className={styles['td-head-tag']}>
              {`${designTag}${stageName ? ' | ' + stageName : ''}`}
            </td>
            <td className={styles['td-head']}>
              Read permission
            </td>
            <td className={styles['td-head']}>
              Write permission
            </td>
            <td className={styles['td-head']}/>
          </tr>
          </thead>
          <tbody className={styles['tbody']}>
          {
            Object.entries((design ?? designs ?? {}).permission ?? {}).map(([key, value]) => {
              return <tr className={styles['tr']} key={key}>
                <td className={styles['td-tag']}>
                  {key}
                </td>
                <td className={styles['td-value']}>
                  <input
                    className={styles["value-field"]}
                    value={value.read ?? 0}
                    type={"number"}
                    onChange={(e) => {setValue(key, 'read', Number(e.target.value))}}
                  >

                  </input>
                </td>
                <td className={styles['td-value']}>
                  <input
                    className={styles["value-field"]}
                    value={value.write ?? 0}
                    type={"number"}
                    onChange={(e) => {setValue(key, 'write', Number(e.target.value))}}
                  >

                  </input>
                </td>
                <td className={styles['td-button']}>
                  <input className={styles["button"]} value={"Delete"} onClick={() => deleteEntry(key)} type="button"/>
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    </div>
    {
      add
        ?
        <div className={styles["add-wrapper"]}>
          <Dropdown currentVal={addString} wide={true} valueList={allKeys} onChange={setAddString} allowOther={true}/>
          <div className={styles["add-buttons"]}>
            <input className={styles["add-button"]} value={"OK"} onClick={() => addEntry(addString)} type="button"/>
            <input className={styles["add-button"]} value={"Close"} onClick={() => {setAdd(false); setAddString('')}} type="button"/>
          </div>
        </div>
        : null
    }
    <LoadingOverlay loading={loading}/>
  </div>
}
