export enum FunctionNames {
  'default',
  'genFunctionParameters',
  'genSwitchParameters',
  'genCopyParameters',
  'genSetParameters',
  'genPushToResultArrayParameters',
  'genCheckInternetParameters',
  'genGetParameters',
  'genResParameters',
  'genQRParameters',
  'genPostParameters',
  'genDownloadParameters',
  'genParseJSONParameters',
  'genEvents',
  'genElements',
  'genSplitStringParameters',
  'genGetConfigParameters',
  'genResURLParameters',
  'genMeasureParameters',
}
