import React, {useEffect, useState} from "react";
import styles from "./config.module.scss";
import stylesGeneric from "../../styles/generics.module.scss";
import {getCookie} from "../../methods/cookie";
import {useNavigate, useParams} from "react-router-dom";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {AppConfig, AppConfigs} from "../../interfaces/AppConfig";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {
  getCodeTypes,
  getDescriptionText,
  getElementMoves,
  getElementTypes,
  getImagesTypes,
  getMandatoryKeys,
  getOrientations,
  getScalingModes,
  getSiteFunctionList
} from "../../methods/getEvents";
import {SaveButton} from "../../components/SaveButton/SaveButton";
import {validateDesign, validateURLs} from "../../methods/validateConfig";
import {fetchDesign, postDesign} from "../../methods/connector";
import {getKeyVal, getPermission, mergeConfigs, openTab, renameKey} from "../../methods/helpers";
import {Background} from "../../components/background/Background";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";
import {InputCheckbox, InputDropdown, InputNumber, InputString} from "../../components/inputs/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesLeft} from "@fortawesome/free-solid-svg-icons";
import {fetchValueAlternative, fillConfig, registerElements, registerTemplates} from "../../methods/templateUtils";
import {useDebouncedCallback} from "use-debounce";
import {genFunctionInputs} from "../../methods/genFunctionInputs";
import {FunctionNames} from "../../interfaces/Constants";

const _ = require('lodash');
let languageStore = require('../../resources/languages/us.json')

let img_perm = require(`../../resources/images/permission-icon.png`)

const permissionDict = {};

const colorBG = '#BBB';

type ParentProps = {
  getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
  design: AppConfig | undefined;
  designs: AppConfigs | undefined;
  designFilled: AppConfig | undefined;
  setter: (baseKey: string, val: any) => void;
  getPermissionType: (e: string, type: string) => (string);
  missingURLs: string[];
  setMissingURLs: React.Dispatch<React.SetStateAction<string[]>>;
  deleteKey: (path: string) => void;
  designTag: string;
  translate: ((e: string) => string);
  isInstance: boolean;
}

type TableElementExtra = {
  text: string,
  path: string,
  type: "number" | "string" | "dropdown" | "checkbox" | "checkboxSmall" | "text",
}

type TableElement = TableElementExtra & {
  valueList?: string[],
  scale?: number,
  defaultVal?: string,
  defaultValBoolean?: string,
}


const TableRow = (props: {
  text?: string,
  empty?: boolean,
  display?: boolean,
  parentProps: ParentProps,
  elements?: (TableElement | React.ReactElement)[],
}) => {
  if (!props.display && props.display !== undefined) {
    return null;
  }
  if (props.empty) {
    return <tr className={styles["table-row"]}>
      <td className={styles['text-cell-empty']}>
      </td>
      <td/>
      <td/>
      <td/>
      <td/>
      <td/>
    </tr>
  }
  if (!props.text) {
    props.text = '';
  }

  const cells: any[] = [
    <td key={`${props.text}--1`} className={styles['text-cell']}>
      {props.parentProps.translate(props.text)}
    </td>
  ];

  for (const [i, element] of Object.entries(props.elements ?? [])) {
    const keys = Object.keys(element);
    if (keys.includes('text') || keys.includes('path')) {
      cells.push(CellElement(element as TableElement, `${props.text}-${i}`, props.parentProps));
    } else {
      cells.push(element);
    }
  }
  for (let i = cells.length; i < 6; i += 1) {
    cells.push(<td className={styles['text-cell-empty']}/>)
  }

  return <tr key={props.text} className={styles["table-row"]}>
    {cells}
  </tr>
}

const CellElement = (props: TableElement, key: string, parentProps: ParentProps) => {
  switch (props.type) {
    case 'number': return <CellNumber key={key} elementKey={key} parentProps={parentProps} text={props.text} path={props.path} scale={props.scale}/>
    case 'string': return <CellString  key={key}elementKey={key} parentProps={parentProps} text={props.text} path={props.path}/>
    case 'dropdown': return <CellDropdown key={key} elementKey={key} parentProps={parentProps} text={props.text} path={props.path} valueList={props.valueList ?? []} defaultVal={props.defaultVal} defaultValBoolean={props.defaultValBoolean}/>
    case 'checkbox': return <CellCheckbox key={key} elementKey={key} parentProps={parentProps} text={props.text} path={props.path}/>
    case 'checkboxSmall': return <CellCheckboxSmall key={key} elementKey={key} parentProps={parentProps} text={props.text} path={props.path}/>
    case 'text': return <CellText key={key} elementKey={key} text={props.text}/>
    default: return <CellNumber key={key} elementKey={key} parentProps={parentProps} text={props.text} path={props.path}/>
  }
}


const CellNumber = (props: {
  text: string,
  path: string,
  elementKey: string,
  scale?: number,
  parentProps: ParentProps
}) => {
  return <>
    <td key={`${props.elementKey}-title`}>
      {props.parentProps.translate(props.text)}
    </td>
    <td key={props.elementKey} className={styles['value-cell']}>
      <div className={styles['value-input-row']}>
        <InputNumber path={props.path} parentProps={props.parentProps} scale={props.scale}/>
      </div>
    </td>
  </>
}

const CellString = (props: {
  text: string,
  path: string,
  elementKey: string,
  parentProps: ParentProps
}) => {
  return <>
    <td key={`${props.elementKey}-title`}>
      {props.parentProps.translate(props.text)}
    </td>
    <td key={props.elementKey} className={styles['value-cell']}>
      <div className={styles['value-input-row']}>
        <InputString path={props.path} parentProps={props.parentProps}/>
      </div>
    </td>
  </>
}

const CellDropdown = (props: {
  text: string,
  path: string,
  elementKey: string,
  valueList: string[],
  defaultVal?: string,
  defaultValBoolean?: string,
  parentProps: ParentProps
}) => {
  return <>
    <td key={`${props.elementKey}-title`}>
      {props.parentProps.translate(props.text)}
    </td>
    <td key={props.elementKey} className={styles['value-cell']}>
      <div className={styles['value-input-row']}>
        <InputDropdown
          path={props.path}
          valueList={props.valueList}
          defaultVal={props.defaultVal}
          booleanDefault={props.defaultValBoolean}
          readOnly={!props.parentProps.getPermissionWrapper(props.path).write}
          type={props.parentProps.getPermissionType(props.path, 'text')}
          parentProps={props.parentProps}
        />
      </div>
    </td>
  </>
}

const CellCheckbox = (props: {
  text: string,
  path: string,
  elementKey: string,
  parentProps: ParentProps
}) => {
  return <>
    <td key={`${props.elementKey}-title`}>
      {props.parentProps.translate(props.text)}
    </td>
    <td key={props.elementKey} className={styles['value-cell']}>
      <div className={styles['value-input-row']}>
        <InputCheckbox
          className={styles['value-input-boolean']}
          path={props.path}
          parentProps={props.parentProps}
        />
      </div>
    </td>
  </>
}


const CellCheckboxSmall = (props: {
  text: string,
  path: string,
  elementKey: string,
  parentProps: ParentProps
}) => {
  return <td key={props.elementKey}>
    {props.parentProps.translate(props.text)}
    <InputCheckbox
      path={props.path}
      parentProps={props.parentProps}
    />
  </td>
}

const CellText = (props: {
  text: string,
  elementKey: string,
}) => {
  return <td key={props.elementKey}>
    {props.text}
  </td>
}


export const ConfigEditor = () => {
  const routeParams = useParams();
  const designTag = routeParams.designTag;
  const stageName = routeParams.stageName;
  let language = window.localStorage.getItem('language') ?? 'us';

  const navigate = useNavigate();
  const jwt = getCookie('jwt');
  const [designs, setDesigns] = useState<AppConfigs>();
  const [design, setDesign] = useState<AppConfig>();
  const [designFilled, setDesignFilled] = useState<AppConfig>();
  const [pageNames, setPageNames] = useState<string[]>([]);
  const [pageRename, setPageRename] = useState<string>(stageName ?? "");
  const [timeoutView , setTimeoutView] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [missingURLs, setMissingURLs] = useState<string[]>([]);

  const imageTypes = getImagesTypes();
  const mandatoryKeys = getMandatoryKeys();
  const elementTypes = getElementTypes();
  const elementMoves = getElementMoves();
  const codeTypes = getCodeTypes();


  const recalcFilledConfig = useDebouncedCallback(() => {
    if (!stageName || !design) {
      return;
    }

    const designsValidate = _.cloneDeep(designs);
    let _t, _e;
    designsValidate!.configs[stageName] = design;
    [designsValidate.templates, _t] = registerTemplates(designsValidate);
    [designsValidate.templatesElements, _e] = registerElements(designsValidate);
    const confFilled = fillConfig(design, designsValidate.templates, designsValidate.templatesElements);

    setDesignFilled(confFilled);

  }, 1000)

  const deleteGeneralKey = (path: string) => {
    if (!design) {
      return;
    }
    if (!([undefined, null, "", 0].includes(getKeyVal(path, design)[0]))) {
      setVal(path, undefined);
    } else {
      const designClone: any = _.cloneDeep(design);
      if (design.deletedKeys?.includes(path) ?? false) {
        designClone.deletedKeys = design!.deletedKeys?.filter(el => el !== path);
      } else {
        if (design.deletedKeys === undefined) {
          designClone.deletedKeys = [];
        }
        designClone.deletedKeys!.push(path);
      }
      setDesign(designClone);
    }
  }



  const translate = (s: string, l: string) => {
    if (!languageStore[s]) {
      return s;
    }
    return languageStore[s];
  }

  const openNextPage = (e: React.MouseEvent<HTMLButtonElement>, inc: number) => {
    if (!stageName || !pageNames) {
      return;
    }
    if (inc === 0) {
      openTab(e, `/generateConfig/${designTag}`);
      return;
    }
    const ind = pageNames.indexOf(stageName);
    if (ind === -1) {
      return;
    }
    if ((ind === 0 && inc === -1) || (ind === (pageNames.length - 1) && inc === 1)) {
      return;
    }
    openTab(e, `/generateConfig/${designTag}/${pageNames[ind + inc]}`);
  }

  const getGCD = (a: number, b: number): number => {
    if (b === 0)  {
      return a;
    }
    return getGCD(b, a%b);
  }

  const getRatio = (ratioType='min') => {
    const bounds = design?.design?.backgroundBounds;
    if (!bounds) {
      return `0/0`;
    }
    let width: number | undefined;
    let height: number | undefined;
    switch (ratioType) {
      case 'min': {
        width = bounds.minWidth
        height = bounds.minHeight
        break;
      }
      case 'max': {
        width = bounds.maxWidth
        height = bounds.maxHeight
        break;
      }
      case 'current': {
        width = design?.design.backgroundSize?.width;
        height = design?.design.backgroundSize?.height;
        break;
      }
      default:
        return `0/0`;
    }
    if (!width || !height) {
      return `0/0`;
    }
    const gcd = getGCD(width, height);
    if (gcd === 0) {
      return `0/0`
    }

    let ratio = (width / (height ?? 1));
    ratio = Math.round(ratio * 100) / 100;

    return `${width/gcd}/${height/gcd} = ${ratio}`

  }

  const copyElement = (key: string) => {
    const el = design?.design?.elements?.[key];
    if (!el) {
      return;
    }
    const dict: Record<string, any> = {};
    dict[key] = el;
    navigator.clipboard.writeText(JSON.stringify(dict))
  }

  const pasteElement = async () => {
    const clipboard = await navigator.clipboard.readText();
    if (!clipboard || !design) {
      return;
    }
    try {
      const el = JSON.parse(clipboard);
      const key = Object.keys(el)[0]
      const val = el[key]
      if (key && val) {
        let currentKey = key;
        let count = 0;
        while (design?.design?.elements?.[currentKey] !== undefined) {
          count += 1;
          currentKey = `${key}_${count}`
        }
        const designClone: any = _.cloneDeep(design);
        const elements = designClone?.design.elements;
        elements[currentKey] = val;
        setDesign(designClone);
        recalcFilledConfig();
      }
    } catch (e) {
      return;
    }
  }

  const setKey = (oldKey: string, newKey: string) => {
    if (oldKey === newKey) {
      return;
    }
    const designClone: any = _.cloneDeep(design);
    const elements = designClone?.design.elements;
    if (!(elements[oldKey])) {
      return;
    }
    elements[newKey] = elements[oldKey];
    delete elements[oldKey];
    setDesign(designClone);
    recalcFilledConfig();
  }

  const setVal = (baseKey: string, val: any) => {
    const perm = getPermissionWrapper(baseKey).write;
    if (!perm) {
      return;
    }

    let designClone: any = _.cloneDeep(design);
    let objInner: any = designClone;
    if (!objInner) {
      return;
    }
    const keySplit = baseKey.split('.');
    for (const key of keySplit.slice(0, keySplit.length - 1)) {
      if (!objInner?.[key]) {
        objInner[key] = {};
      }
      objInner = objInner[key];
    }
    val = val === '' ? undefined : val;
    if (val !== undefined) {
      objInner[keySplit[keySplit.length - 1]] = val;
    } else {
      delete objInner[keySplit[keySplit.length - 1]];
    }
    setDesign(designClone);
    recalcFilledConfig();
  }

  const addNewKeyWrapper = (e: React.KeyboardEvent<HTMLInputElement>, elemName: string) => {
    if (e.key !== "Enter") {
      return
    }
    //@ts-ignore
    const newKey = e.target.value;
    addNewKey(newKey, elemName)
  }

  const addNewKey = (newKey: string, elemName: string) => {
    const perm = getPermissionWrapper(`design.elements.${elemName}.css?.${newKey}`).write;
    if (!perm) {
      return;
    }

    let designClone: AppConfig = _.cloneDeep(design);
    if (designClone?.design?.elements?.[elemName]?.css?.[newKey]) {
      return;
    }

    const key = `elements.${elemName}.css`;
    const keySplit = key.split(".");
    let objInner = designClone.design;
    for (const key of keySplit.slice(0, keySplit.length)) {
      if (!objInner?.[key]) {
        objInner[key] = {};
      }
      objInner = objInner[key];
    }

    let css = designClone.design.elements?.[elemName]?.css;
    if (!css && designClone?.design?.elements?.[elemName] !== undefined) {
      designClone.design.elements[elemName].css = {};
      css = designClone.design.elements?.[elemName]?.css;
    }
    //@ts-ignore
    css[newKey] = "";
    setDesign(designClone);
    recalcFilledConfig();
  }

  const addNewKeyButton = (id: string, elemName: string) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    // @ts-ignore
    addNewKey(element.value, elemName)
  }

  const deleteKey = (elemName: string, keyName: string) => {
    const perm = getPermissionWrapper(`design.elements.${elemName}.css?.${keyName}`).write;
    if (!perm) {
      return;
    }
    let designClone: AppConfig = _.cloneDeep(design);
    if (designClone?.design?.elements?.[elemName]?.css?.[keyName] === undefined) {
      return;
    }
    if (mandatoryKeys.includes(keyName)) {
      return;
    }

    const css = designClone.design.elements?.[elemName].css;
    //@ts-ignore
    delete css[keyName];
    setDesign(designClone);
    recalcFilledConfig();
  }

  const addNewElementWrapper = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return
    }
    //@ts-ignore
    const newKey = e.target.value;
    addNewElement(newKey)
  }

  const addNewElementButton = (id: string) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    // @ts-ignore
    addNewElement(element.value)
  }

  const addNewElement = (newKey: string) => {
    const perm = getPermissionWrapper(`design.elements.${newKey}`).write;
    if (!perm) {
      return;
    }

    let designClone: AppConfig = _.cloneDeep(design);
    if (!designClone?.design?.elements) {
      return;
    }

    const elements = designClone.design.elements;
    //@ts-ignore
    elements[newKey] = {css: {position: 'fixed'}};
    setDesign(designClone);
    recalcFilledConfig();
  }

  const deleteElement = (name: string) => {
    const perm = getPermissionWrapper(`design.elements.${name}`).write;
    if (!perm) {
      return;
    }
    let designClone: AppConfig = _.cloneDeep(design);
    if (!designClone?.design?.elements) {
      return;
    }

    const elements = designClone.design.elements;
    delete elements[name];
    //@ts-ignore
    setDesign(designClone);
    recalcFilledConfig();
  }

  const renamePage = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      return;
    }
    if (!stageName || stageName === pageRename) {
      return;
    }
    let designsClone: AppConfigs = _.cloneDeep(designs);
    if (!designsClone?.configs?.[stageName]) {
      return;
    }
    designsClone.configs[pageRename] = designsClone.configs[stageName];
    delete designsClone.configs[stageName];
    renameKey(designsClone, stageName, pageRename);
    setLoading(true);
    const res = await postDesign(designsClone, jwt);
    setLoading(false);
    if (res.status !== 200) {
      return;
    }
    navigate(`../${pageRename}`, {relative: "path"});
  }

  const copyTemplate = (tempName: string) => {
    const templateStage: AppConfig = _.cloneDeep(designs?.configs?.[tempName]);
    let designClone: AppConfig = _.cloneDeep(design);
    if (!templateStage) {
      return;
    }

    mergeConfigs(designClone, templateStage);
    setDesign(designClone);
    recalcFilledConfig();
  }

  const renderSwitchSettings = (fieldType: string, funcName: string, index: number, key: string, parentProp: any, placeholder?: string) => {

    switch(fieldType) {
      case 'number':
        return <InputNumber path={`settings.${funcName}.${index}.${key}`} parentProps={parentProp}/>
      case 'string':
        return <InputString path={`settings.${funcName}.${index}.${key}`} placeholder={translate(placeholder ?? '', language)} parentProps={parentProp}/>
      case 'boolean':
        return <InputDropdown
          path={`settings.${funcName}.${index}.${key}`}
          valueList={['true', 'false']}
          shouldTranslate={placeholder}
          defaultVal={placeholder}
          parentProps={parentProps}
        />
    }
  }

  const genFunctionInputsWrapper = (functionName: FunctionNames) => {
    return genFunctionInputs(
      functionName,
      parentProps,
      language,
      translate,
      getPermissionWrapper,
      getPermissionType,
      pageNames,
      colorBG,
      setDesign,
      recalcFilledConfig,
      setKey,
      deleteKey,
      deleteElement,
      copyElement,
      pasteElement,
      addNewKeyWrapper,
      addNewKeyButton,
      addNewElementWrapper,
      addNewElementButton,
      design,
      designFilled,
      designs,
    )
  }

  const genFunctionInputsFunction = (functionNameRaw: string) => {
    let functionName: FunctionNames = FunctionNames.default;
    switch (designFilled?.settings.callFunction ?? '') {
      case 'if': functionName = FunctionNames.genFunctionParameters; break;
      case 'switch': functionName = FunctionNames.genSwitchParameters; break;
      case 'copyValues': functionName = FunctionNames.genCopyParameters; break;
      case 'setValues': functionName = FunctionNames.genSetParameters; break;
      case 'splitString': functionName = FunctionNames.genSplitStringParameters; break;
      case 'pushToResultArray': functionName = FunctionNames.genPushToResultArrayParameters; break;
      case 'checkInternet': functionName = FunctionNames.genCheckInternetParameters; break;
      case 'getRequest': functionName = FunctionNames.genGetParameters; break;
      case 'genResultURL': functionName = FunctionNames.genResParameters; break;
      case 'getConfig': functionName = FunctionNames.genGetConfigParameters; break;
      case 'scanQRGeneric': functionName = FunctionNames.genQRParameters; break;
      case 'postRequest': functionName = FunctionNames.genPostParameters; break;
      case 'downloadMedia': functionName = FunctionNames.genDownloadParameters; break;
      case 'parseJSON': functionName = FunctionNames.genParseJSONParameters; break;
      case 'getUSBSerial': functionName = FunctionNames.genResParameters; break;
      case 'measureAOX': functionName = FunctionNames.genMeasureParameters; break;
      case 'measureAOXSplit': functionName = FunctionNames.genMeasureParameters; break;
      case 'measureAOXHalf': functionName = FunctionNames.genMeasureParameters; break;
      case 'uploadDataAOX': functionName = FunctionNames.genMeasureParameters; break;
      default: break;
    }
    return genFunctionInputs(
      functionName,
      parentProps,
      language,
      translate,
      getPermissionWrapper,
      getPermissionType,
      pageNames,
      colorBG,
      setDesign,
      recalcFilledConfig,
      setKey,
      deleteKey,
      deleteElement,
      copyElement,
      pasteElement,
      addNewKeyWrapper,
      addNewElementButton,
      addNewElementWrapper,
      addNewElementButton,
      design,
      designFilled,
      designs,
    )
  }

  const postConfig = async () => {
    if (!designs || !design || !stageName || timeoutView || missingURLs.length > 0) {
      return;
    }
    const designsValidate = _.cloneDeep(designs);
    let templatesValid = true;
    let elementsValid = true;
    designsValidate!.configs[stageName] = design;
    [designsValidate.templates, templatesValid] = registerTemplates(designsValidate);
    [designsValidate.templatesElements, elementsValid] = registerElements(designsValidate);
    if (!templatesValid) {
      window.alert("The page-template leads to a cyclic dependency, or the template-name is already used. Please correct this.")
      return;
    }
    if (!elementsValid) {
      window.alert("One of the element-template leads to a cyclic dependency, or its template-name is already used. Please correct this.")
      return;
    }
    const confFilled = fillConfig(design, designsValidate.templates, designsValidate.templatesElements);
    const {status, errorMsg} = validateDesign(confFilled, pageNames);
    if (!status) {
      window.alert(errorMsg);
      return;
    }
    const missingNames = await validateURLs(confFilled);
    if (missingNames.length > 0) {
      setMissingURLs(missingNames);
      return;
    }
    designs!.configs[stageName] = design;

    designs.templates = designsValidate.templates;
    designs.templatesElements = designsValidate.templatesElements;
    setDesignFilled(confFilled);


    setLoading(true);
    const data = await postDesign(designs, jwt)
    setLoading(false);
    if (data.status !== 200) {
      return;
    }
    setTimeoutView(true);
    setTimeout(() => {setTimeoutView(false)}, 1000);
  }

  const getPermissionWrapper = (e: string) => {
    if (!designs || !design) {
      return {read: false, write: false};
    }
    return getPermission(e, designs, permissionDict, designs.userPermission, design);
  }

  const getPermissionType = (e: string, type: string) => {
    if (!designs || !design) {
      return type;
    }
    const perm = getPermission(e, designs, permissionDict, designs.userPermission, design);
    if (!perm.read) {
      return 'password'
    }
    return type
  }


  useEffect(() => {
    const getDesigns = async() => {
      if (!designTag) {
        return;
      }
      const r = await fetchDesign(designTag, jwt);
      if (!r) {
        return;
      }
      const conf = r.configs?.[stageName ?? ''];
      const pageNames = Object.keys(r.configs).sort();
      const [templates, _t] = registerTemplates(r);
      const [elements, _e] = registerElements(r);
      const confFilled = fillConfig(conf, templates, elements);
      setDesign(conf);
      setDesignFilled(confFilled);
      setDesigns(r);
      setPageNames(pageNames);
      languageStore = require(`../../resources/languages/${language}.json`)
    }
    getDesigns();
  }, [])

  const elemIsInstance = (Object.values(design?.design?.elements ?? {})).some((el) => ![undefined, null, ""].includes(el.useTemplate));

  const parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design: AppConfig | undefined;
    designs: AppConfigs | undefined;
    designFilled: AppConfig | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    missingURLs: string[];
    setMissingURLs: React.Dispatch<React.SetStateAction<string[]>>;
    deleteKey: (path: string) => void;
    designTag: string;
    translate: ((e: string) => string);
    isInstance: boolean;
  } = {
    setter: setVal,
    design,
    designs,
    designFilled,
    getPermissionWrapper,
    getPermissionType,
    missingURLs: missingURLs,
    setMissingURLs: setMissingURLs,
    deleteKey: deleteGeneralKey,
    designTag: designTag ?? "",
    translate: ((e: string) => translate(e, language)),
    isInstance: (![undefined, null, ""].includes(design?.settings.useTemplate)) || elemIsInstance,
  }

  return (
    <div className={stylesGeneric["page"]}>
      <Background/>
      <div className={stylesGeneric["settings-button-row"]}>
        <div className={stylesGeneric["settings-button"]}>
          <HomeButton/>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <SaveButton onClick={postConfig}/>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onClick={() => {
              window.open(`${window.location.href}/preview`, '_blank', 'toolbar=0,location=0,menubar=0')
            }}>
            PREVIEW
          </button>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onMouseDown={(e) => openTab(e, `/generateConfigPermission/${designTag}/${stageName}`)}
          >
            <img
              className={stylesGeneric['generic-button']}
              src={img_perm}
              alt={""}
            />
          </button>
        </div>

        <div className={stylesGeneric["settings-button-spacer"]}/>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onMouseDown={(e) => openNextPage(e, 0)}
          >
            <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faAnglesLeft}/>
          </button>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onMouseDown={(e) => openNextPage(e, -1)}
          >
            <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faAngleLeft}/>
          </button>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <button
            className={stylesGeneric['generic-button-wrapper']}
            onMouseDown={(e) => openNextPage(e, 1)}
          >
            <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faAngleRight}/>
          </button>
        </div>
      </div>
      <div className={stylesGeneric["content-wrapper"]}>
        <div className={stylesGeneric["page-wrapper"]}>
          <div className={styles["title-row"]}>
            <div className={styles["title-cell"]}>
              <h3 className={styles["title-designtag"]}>
                {designTag}
              </h3>
            </div>
            <div className={styles["title-cell"]}>
              <input
                className={styles['page-title-input']}
                value={pageRename}
                onChange={(e) => setPageRename(e.target.value.replaceAll('.', ''))}
                onKeyDown={renamePage}
              />
            </div>
          </div>
          <div className={styles['table-cell-comment']}>
            <InputString placeholder={'Comment...'} path={'design.comment'} parentProps={parentProps}/>
          </div>
          <table>
            <thead>
            </thead>
            <tbody>

            <tr className={styles["table-row"]}>
              <td className={styles['text-cell-empty']}>
              </td>
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
            </tr>

            <TableRow
              parentProps={parentProps}
              text={'backgroundResolution'}
              elements={[
                {
                  text: 'backgroundResolutionWidth',
                  path: 'design.backgroundSize.width',
                  type: 'number'
                },
                {
                  text: 'backgroundResolutionHeight',
                  path: 'design.backgroundSize.height',
                  type: 'number'
                },
                {
                  text: getRatio('current'),
                  path: 'none',
                  type: 'text'
                }
              ]}
            />



            <TableRow
              parentProps={parentProps}
              text={'backgroundImage'}
              elements={[
                {
                  text: 'backgroundImageString',
                  path: 'design.background',
                  type: 'string'
                },
                {
                  text: 'backgroundImageType',
                  path: 'design.backgroundType',
                  type: 'dropdown',
                  valueList: imageTypes
                },
                {
                  text: 'backgroundImageBorder',
                  path: 'design.backgroundSize.hasBorder',
                  type: 'checkboxSmall'
                }
              ]}
            />


            <TableRow
              parentProps={parentProps}
              text={'backgroundBorderSize'}
              display={design?.design?.backgroundSize?.hasBorder}
              elements={[
                {
                  text: 'backgroundBorderWidth',
                  path: 'design.backgroundSize.sectionWidth',
                  type: 'number'
                },
                {
                  text: 'backgroundBorderHeight',
                  path: 'design.backgroundSize.sectionHeight',
                  type: 'number'
                }
              ]}
            />

            <TableRow
              parentProps={parentProps}
              text={'backgroundBorderOrigin'}
              display={design?.design?.backgroundSize?.hasBorder}
              elements={[
                {
                  text: 'backgroundBorderX',
                  path: 'design.backgroundSize.sectionX',
                  type: 'number'
                },
                {
                text: 'backgroundBorderY',
                path: 'design.backgroundSize.sectionY',
                type: 'number'
                }
              ]}
            />

            <TableRow
              parentProps={parentProps}
              text={'backgroundBorderAdditional'}
              display={design?.design?.backgroundSize?.hasBorder}
              elements={[
                {
                  text: 'backgroundBorderScalingMode',
                  path: 'design.backgroundSize.scaleMode',
                  type: 'dropdown',
                  valueList: getScalingModes(),
                  defaultVal: 'contain',
                }
              ]}
            />

            <TableRow
              parentProps={parentProps}
              empty={true}
            />

            <TableRow
              parentProps={parentProps}
              text={'backgroundResolutionWide'}
              elements={[
                {
                  text: 'backgroundResolutionWidth',
                  path: 'design.backgroundBounds.maxWidth',
                  type: 'number'
                },
                {
                  text: 'backgroundResolutionHeight',
                  path: 'design.backgroundBounds.maxHeight',
                  type: 'number'
                },
                {
                  text: getRatio('max'),
                  path: 'none',
                  type: 'text'
                }
              ]}
            />

            <TableRow
              parentProps={parentProps}
              text={'backgroundResolutionNarrow'}
              elements={[
                {
                  text: 'backgroundResolutionWidth',
                  path: 'design.backgroundBounds.minWidth',
                  type: 'number'
                },
                {
                  text: 'backgroundResolutionHeight',
                  path: 'design.backgroundBounds.minHeight',
                  type: 'number'
                },
                {
                text: getRatio('min'),
                path: 'none',
                type: 'text'
                }
              ]}
            />
            <TableRow
              parentProps={parentProps}
              text={'backgroundResolutionGoto'}
              elements={[
                {
                  text: '',
                  path: 'design.backgroundBounds.goTo',
                  type: 'dropdown',
                  valueList: pageNames
                }
              ]}
            />

            <TableRow
              parentProps={parentProps}
              empty={true}
            />

            <TableRow
              parentProps={parentProps}
              text={'screenOrientation'}
              elements={[
                {
                  text: 'screenOrientationLock',
                  path: 'design.orientation.lock',
                  type: 'dropdown',
                  valueList: ['true', 'false'],
                  defaultValBoolean: 'true'
                },
                {
                  text: 'screenOrientationValue',
                  path: 'design.orientation.value',
                  type: 'dropdown',
                  valueList: getOrientations(),
                  defaultVal: 'portrait'
                }
              ]}
            />
            <TableRow
              parentProps={parentProps}
              text={'scroll'}
              elements={[
                {
                  text: 'scrollX',
                  path: 'design.scroll.scrollX',
                  type: 'checkbox'
                },
                {
                  text: 'scrollY',
                  path: 'design.scroll.scrollY',
                  type: 'checkbox'
                }
              ]}
            />
            <TableRow
              parentProps={parentProps}
              text={'copyScreenDesign'}
              elements={[
                  <td key={'copyScreenDesign-caption'}/>,
                  <td key={'copyScreenDesign-entry'}>
                    <Dropdown
                      currentVal={fetchValueAlternative(`design.template`, design, designFilled) ?? ""}
                      valueList={pageNames}
                      onChange={(val: string) => {
                        setVal('design.template', val);
                        copyTemplate(val)
                      }}
                      readOnly={!getPermissionWrapper('design.template').write}
                      type={getPermissionType('design.template', 'text')}
                    />
                  </td>
              ]}
            />
            <TableRow
              parentProps={parentProps}
              text={'template'}
              elements={[
                {
                  text: 'useTemplate',
                  path: 'settings.useTemplate',
                  type: 'dropdown',
                  valueList: Object.keys(designs?.templates ?? {})
                },
                {
                  text: 'templateName',
                  path: 'settings.templateName',
                  type: 'string'
                }
              ]}
            />
            <TableRow
              parentProps={parentProps}
              text={'timeout'}
              elements={[
                {
                  text: 'timeoutAfter',
                  path: 'settings.timeout.timer',
                  type: 'number',
                  scale: 1/1000
                },{
                  text: 'timeoutGoto',
                  path: 'settings.timeout.onExpiration',
                  type: 'dropdown',
                  valueList: ['previousScreen', ...pageNames]
                }
              ]}
            />
            <TableRow
              parentProps={parentProps}
              text={'function'}
              elements={[
                {
                  text: '',
                  path: 'settings.callFunction',
                  type: 'dropdown',
                  valueList: getSiteFunctionList()
                }
              ]}
            />
            {
              genFunctionInputsFunction(designFilled?.settings.callFunction ?? '')
            }
            {
              genFunctionInputsWrapper(FunctionNames.genEvents)
            }
            {
              genFunctionInputsWrapper(FunctionNames.genElements)
            }
            </tbody>
          </table>
        </div>
      </div>
      {
        timeoutView
          ? <div className={styles['timeout-view']}/>
          : null
      }
      <LoadingOverlay loading={loading}/>
    </div>
  )
}
