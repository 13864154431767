import React, {ChangeEvent, useEffect, useState} from "react";
import styles from "./menu.module.scss";
import DatePicker from "react-datepicker"
import stylesGeneric from "../../styles/generics.module.scss";
import { getCookie } from "../../methods/cookie";
import {useNavigate, useParams} from "react-router-dom";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {AppConfigs} from "../../interfaces/AppConfig";
import {downloadFile, fetchDesign, fetchWrapper, postDesign} from "../../methods/connector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGear, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {SaveButton} from "../../components/SaveButton/SaveButton";
import {Background} from "../../components/background/Background";
import {getConstants, getLanguageCodes, getLanguages} from "../../methods/getEvents";
import {logout, openTab} from "../../methods/helpers";
import "react-datepicker/dist/react-datepicker.css";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";
import {validateLanguageConfig} from "../../methods/validateConfig";

const _ = require('lodash');

const languageCodes = getLanguageCodes()

// list all stages for the given config, with a button to open and edit them
export const LanguageMenu = () => {
  const routeParams = useParams();
  const designTag = routeParams.designTag;

  const navigate = useNavigate();
  const jwt = getCookie('jwt');

  const [design, setDesign] = useState<AppConfigs>();
  const [add, setAdd] = useState<boolean>(false);
  const [addString, setAddString] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [newLanguage, setNewLanguage] = useState<string>("");

  const addEntry = async () => {
    if (!design || newLanguage === "") {
      return;
    }
    const d = _.cloneDeep(design)

    if (d?.language?.[newLanguage]) {
      return;
    }

    if (!(d?.language)){
      d.language = {};
    }
    d.language[newLanguage] = {};
    setLoading(true);
    const data = await postDesign(d, jwt)
    setLoading(false);
    if (data.status !== 200) {
      return;
    }
    getDesign();
  }

  const deleteEntry = async (key: string) => {
    if (!design) {
      return;
    }
    const d = _.cloneDeep(design)

    if (!(d?.language?.[key])) {
      return;
    }
    delete d.language[key];

    setLoading(true);
    const data = await postDesign(d, jwt)
    setLoading(false);
    if (data.status !== 200) {
      return;
    }
    getDesign();
  }

  const exportLanguage = () => {
    if ((design?.userPermission ?? 0) < 200) {
      window.alert("Insufficient permission level.");
      return;
    }
    if (!(design?.language) || !designTag) {
      return;
    }
    downloadFile(design.language, `${designTag}_languages`);
  }

  const importLanguage = (e: ChangeEvent<HTMLInputElement>) => {
    if ((design?.userPermission ?? 0) < 400) {
      window.alert("Insufficient permission level.");
      return;
    }
    if (!(e.target.files)){
      return;
    }
    setLoading(true);
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = async el => {
      if (!el?.target?.result || el.target.result instanceof ArrayBuffer) {
        setLoading(false);
        return;
      }
      try {
        const data: AppConfigs['language'] = JSON.parse(el.target.result);
        if (!data || Object.keys(data).length === 0) {
          setLoading(false);
          return;
        }
        const d = _.cloneDeep(design)
        d['language'] = data;
        await postDesign(d, jwt)
        getDesign();
      } catch (e) {
        setLoading(false);
        return;
      }
    };

  }


  const getDesign = async () => {
    let url = `${process.env.REACT_APP_URL}/designTag/${designTag}`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'get');
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r: AppConfigs = await data.json();
    setDesign(r);
  }

  useEffect(() => {
    getDesign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className={stylesGeneric["page"]}>
    <Background/>
    {/*<HomeButtonRow/>*/}
    <div className={stylesGeneric["settings-button-row"]}>
      <div className={stylesGeneric["settings-button"]}>
        <HomeButton/>
      </div>
      <div className={stylesGeneric["settings-button"]}>
        <button className={stylesGeneric['generic-button-wrapper']} onClick={() => {
          setAdd(!add);
        }}>
          <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faPlus}/>
        </button>
      </div>
    </div>
    <div className={stylesGeneric["content-wrapper"]}>
      <div className={stylesGeneric["page-wrapper"]}>
        <table className={styles['table']}>
          <thead className={styles['thead']}>
            <tr className={styles['tr-head']}>
              <td className={styles['td-head-tag']}>
                Language
              </td>
              <td className={styles['td-head']}/>
              <td className={styles['td-head']}/>
              <td className={styles['td-head']}>
                <button className={styles["export-button"]} value={"Export"} onClick={(e) => {e.stopPropagation(); exportLanguage();}}>
                  Export
                </button>
              </td>
              <td className={styles["td-head"]}>
                <input
                  type="file"
                  className={styles["import-hide"]}
                  id={`file-upload`}
                  onChange={(e) => {importLanguage(e)}}
                />
                <button
                  className={styles["import-button"]}
                  value="Import"
                >
                  <label className={styles["import-button-label"]} htmlFor={`file-upload`}>
                    Import
                  </label>
                </button>
              </td>
            </tr>
          </thead>
          <tbody className={styles['tbody']}>
          {
            Object.entries(design?.language ?? {}).map(([key, value]) => {
              return <tr className={styles['tr']} key={key}>
                <td className={styles['td-tag']}>
                  {key}
                </td>
                <td className={styles['td']}>
                  {languageCodes[key]}
                </td>
                <td className={styles[validateLanguageConfig(design, key) ? 'td-button' : 'td-button-outline']}>
                  <input className={styles["button"]} value={"Edit"}
                         onMouseDown={(e) => openTab(e, `/generateConfigLanguage/${designTag}/${key}`)}
                         type="button"/>
                </td>
                <td className={styles['td-button']}>
                  <input className={styles["button"]} value={"Translate"}
                         onMouseDown={(e) => openTab(e, `/generateConfigLanguage/${designTag}/translate/${key}`)}
                         type="button"/>
                </td>
                <td className={styles['td-button']}>
                  <input className={styles["button"]} value={"Delete"} onClick={() => deleteEntry(key)} type="button"/>
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    </div>
    {
      add
        ?
        <div className={styles["add-wrapper"]}>
          <Dropdown currentVal={newLanguage} wide={true} valueList={getLanguages()} onChange={(e) => setNewLanguage(e)}/>
          <div className={styles["add-buttons"]}>
            <input className={styles["add-button"]} value={"OK"} onClick={addEntry} type="button"/>
            <input className={styles["add-button"]} value={"Close"} onClick={() => {setAdd(false); setNewLanguage('')}} type="button"/>
          </div>
        </div>
        : null
    }
    <LoadingOverlay loading={loading}/>
  </div>
}
