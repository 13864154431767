import React, {useEffect, useRef, useState} from "react";
import  styles from "./Input.module.scss";
import  stylesDropdown from "../dropdown/Dropdown.module.scss";
import stylesConfig from "../../screens/configGenerator/config.module.scss"
import {AppConfig, AppConfigs} from "../../interfaces/AppConfig";
import {getKeyVal, getKeyValGeneric, openTab} from "../../methods/helpers";
import {colorBlue, colorRed} from "../renameField/RenameField";


const colorBG = '#BBB';

export const InputCheckbox = (props: {
  path: string,
  readOnly?: boolean,
  className?: string
  parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design: AppConfig | undefined;
    designFilled: AppConfig | undefined;
    designs: AppConfigs | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    deleteKey: (path: string) => void;
    designTag: string;
    translate: ((e: string) => string);
    isInstance: boolean;
  }
}) => {

  let [val, originLevel] = getKeyVal(props.path, props.parentProps.design, props.parentProps.designFilled);
  if (val === undefined || val === null) {
    val = false;
  }

  let deleted = false;
  if (props.parentProps.design?.deletedKeys?.includes(props.path) && originLevel !== 0) {
    deleted = true;
  }

  let color = "#000000";
  switch (originLevel) {
    case 1: color = colorBlue; break;
  }
  if (deleted) {
    color = colorRed;
  }

  return <>
    <input
      className={styles['value-input-boolean']}
      type='checkbox'
      checked={val}
      onChange={(e) => props.parentProps.setter(props.path, !val)}
      readOnly={!props.parentProps.getPermissionWrapper(props.path).write}
      style={!props.parentProps.getPermissionWrapper(props.path).write ? {opacity: 0.5, color} : {color}}
    />
    {
      props.parentProps.isInstance
        ? <input
          type={"button"}
          style={{color: deleted ? "#0F0" : "#F00"}}
          value={deleted ? "✓" : "❌"}
          onClick={() => props.parentProps.deleteKey(props.path)}
        />
        : null
    }
  </>

}

export const InputString = (props: {
  path: string,
  placeholder?: string,
  parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design: AppConfig | undefined;
    designFilled: AppConfig | undefined;
    designs: AppConfigs | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    missingURLs: string[];
    setMissingURLs: React.Dispatch<React.SetStateAction<string[]>>;
    deleteKey: (path: string) => void;
    isInstance: boolean;
  }
}) => {

  const [val, originLevel] = getKeyVal(props.path, props.parentProps.design, props.parentProps.designFilled);
  const isMissing = props.parentProps.missingURLs.includes(val ?? "") ?? false;

  let deleted = false;
  if (props.parentProps.design?.deletedKeys?.includes(props.path) && originLevel !== 0) {
    deleted = true;
  }

  let color = "#000000";
  switch (originLevel) {
    case 1: color = colorBlue; break;
  }
  if (deleted) {
    color = colorRed;
  }

  return <div>
    <input
      className={isMissing ? `${styles['value-input-string']} ${styles['value-input-missing']}` : styles['value-input-string']}
      value={val ?? ""}
      onChange={(e) => props.parentProps.setter(props.path, e.target.value)}
      onMouseOver={() =>
        props.parentProps.setMissingURLs(props.parentProps.missingURLs.filter((el) => el !== (val ?? "")))
      }
      placeholder={props.placeholder ?? ""}
      readOnly={!props.parentProps.getPermissionWrapper(props.path).write}
      type={props.parentProps.getPermissionType(props.path, 'text')}
      style={!props.parentProps.getPermissionWrapper(props.path).write ? {backgroundColor: colorBG, color} : {color}}
    />
    {
      props.parentProps.isInstance
        ? <input
          type={"button"}
          style={{color: deleted ? "#0F0" : "#F00"}}
          value={deleted ? "✓" : "❌"}
          onClick={() => props.parentProps.deleteKey(props.path)}
        />
        : null
    }

  </div>
}

export const InputNumber = (props: {
  path: string,
  scale?: number,
  parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design: AppConfig | undefined;
    designFilled: AppConfig | undefined;
    designs: AppConfigs | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    deleteKey: (path: string) => void;
    isInstance: boolean;
  }
}) => {

  const [val, originLevel] = getKeyVal(props.path, props.parentProps.design, props.parentProps.designFilled, 'number');
  const scale = props.scale ?? 1;

  let deleted = false;
  if (props.parentProps.design?.deletedKeys?.includes(props.path) && originLevel !== 0) {
    deleted = true;
  }

  let color = "#000000";
  switch (originLevel) {
    case 1:
      color = colorBlue;
      break;
  }
  if (deleted) {
    color = colorRed;
  }

  return <div>
    <input
      className={styles['value-input-number']}
      value={(val ?? 0) * scale}
      onChange={(e) => props.parentProps.setter(props.path, Number(e.target.value) / scale)}
      readOnly={!props.parentProps.getPermissionWrapper(props.path).write}
      type={props.parentProps.getPermissionType(props.path, 'number')}
      style={!props.parentProps.getPermissionWrapper(props.path).write ? {backgroundColor: colorBG, color} : {color}}
    />
    {
      props.parentProps.isInstance
        ? <input
          type={"button"}
          style={{color: deleted ? "#0F0" : "#F00"}}
          value={deleted ? "✓" : "❌"}
          onClick={() => props.parentProps.deleteKey(props.path)}
        />
        : null
    }
  </div>
}

export const InputDropdown = (props: {
  path: string,
  valueList: string[],
  wide?: boolean,
  scale?: number,
  allowOther?: boolean,
  readOnly?: boolean,
  type?: string,
  requiresEnter?: boolean,
  shouldTranslate?: string,
  booleanDefault?: string,
  defaultVal?: string,
  parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design: AppConfig | undefined;
    designFilled: AppConfig | undefined;
    designs: AppConfigs | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    deleteKey: (path: string) => void;
    designTag: string;
    translate: ((e: string) => string);
    isInstance: boolean;
  }
}) => {

  let [currentVal, originLevel] = getKeyVal(props.path, props.parentProps.design, props.parentProps.designFilled, props.booleanDefault);


  if (props.booleanDefault !== undefined && props.booleanDefault !== "") {
    if ([undefined, null].includes(currentVal)) {
      currentVal = props.booleanDefault
    } else {
      currentVal = currentVal.toString();
    }
  }

  if ([null, undefined].includes(currentVal)) {
    currentVal = props.defaultVal ?? "";
    if (currentVal !== "") {
      currentVal = props.shouldTranslate ? props.parentProps.translate(currentVal) : currentVal;
    }
  }

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchVal, setSearchVal] = useState<string>("");
  const [matches, setMatches] = useState<string[]>([]);

  const onMouse = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.button === 0) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    if (
      !(e.button === 1
        && props.parentProps.designTag !== undefined
        && !([undefined, null, ""].includes(currentVal))
      )) {
      return;
    }

    openTab(e, `/generateConfig/${props.parentProps.designTag}/${currentVal}`)
  }

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)){
        if (!props.allowOther) {
          setSearchVal("");
        }
        getMatches(event);
        return;
        //@ts-ignore
      } else if (event.target.tagName!== "LI") {
        getMatches(event);
      }
    }    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, props.valueList]);

  const setSearchValWrapper = (val: string) => {
    setSearchVal(val);
    getMatches(undefined, val);
    if(props.allowOther && (!(props.requiresEnter))) {
      props.parentProps.setter(props.path, val);
    }
  }
  const getMatches = (event?: Event, search?: string) => {
    const currentVal = search ?? searchVal;
    if (event) {
      const isActive = event.target === inputRef.current;
      if (currentVal === "" && !isActive) {
        setMatches([]);
        return;
      }
    }
    setMatches([...props.valueList.sort(), 'clear'].filter((val) => val.toLowerCase().includes(currentVal.toLowerCase())));
  }

  const handleChange = (val: string) => {
    setSearchVal("");
    props.parentProps.setter(props.path, val === 'clear' ? '' : val);
    setMatches([]);
  }

  let deleted = false;
  if (props.parentProps.design?.deletedKeys?.includes(props.path) && originLevel !== 0) {
    deleted = true;
  }

  let color = "#000000";
  switch (originLevel) {
    case 1:
      color = colorBlue;
      break;
  }
  if (deleted) {
    color = colorRed;
  }

  // getMatches(inputRef);
  // const matches = getMatches()

  return <div className={stylesDropdown['wrapper']} ref={wrapperRef}>
    <input
      className={stylesConfig[`${props.wide ? 'value-input-string-wide' : 'value-input-string'}`]}
      ref={inputRef}
      value={searchVal}
      placeholder={props.type === 'password' ? '' : currentVal.toString()}
      onChange={(e) => {
        setSearchValWrapper(e.target.value)
      }}
      onKeyDown={(e) => {
        if (e.key !== "Enter" || !props.requiresEnter) {
          return
        }
        props.parentProps.setter(props.path, searchVal);
        setSearchValWrapper('')
      }}
      onMouseDown={(e) => {
        onMouse(e)
      }}
      onContextMenu={(e) => {
        e.preventDefault()
      }}
      contextMenu={'false'}
      type={props.type}
      readOnly={props.readOnly}
      style={props.readOnly ? {backgroundColor: colorBG, color} : {color}}
    />
    {
      matches.length > 0 && !props.readOnly
        ?
        <ul className={stylesDropdown['options']}>
          {matches.map((val) => {
            return <li
              className={val === currentVal ? `${stylesDropdown['option']} ${stylesDropdown['option-active']}` : stylesDropdown['option']}
              key={val}
              onClick={() => {
                handleChange(val)
              }}
            >
              {val}
            </li>
          })}
        </ul>
        : null
    }
    {
      props.parentProps.isInstance
        ? <input
          type={"button"}
          style={{color: deleted ? "#0F0" : "#F00"}}
          value={deleted ? "✓" : "❌"}
          onClick={() => props.parentProps.deleteKey(props.path)}
        />
        : null
    }
  </div>
}


export const InputCheckboxGeneric = (props: {
  path: string,
  readOnly?: boolean,
  parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design?: AppConfigs | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType?: (e: string, type: string) => (string);
    deleteKey: (path: string) => void;
    designTag: string;
    translate: ((e: string) => string);
  }
}) => {

  let val = getKeyValGeneric(props.path, props.parentProps.design);
  if (val === undefined || val === null) {
    val = false;
  }


  let color = "#000000";

  return <div>
    <input
      className={styles['value-input-boolean']}
      type='checkbox'
      checked={val}
      onChange={(e) => props.parentProps.getPermissionWrapper(props.path).write ? props.parentProps.setter(props.path, !val) : (() => {})}
      readOnly={!props.parentProps.getPermissionWrapper(props.path).write}
      style={!props.parentProps.getPermissionWrapper(props.path).write ? {opacity: 0.5, color} : {color}}
    />
  </div>

}

export const InputStringGeneric = (props: {
  path: string,
  placeholder?: string,
  parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design: AppConfigs | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    deleteKey: (path: string) => void;
  }
}) => {

  const val = getKeyValGeneric(props.path, props.parentProps.design);

  let color = "#000000";

  return <div>
    <input
      className={styles['value-input-string']}
      value={val ?? ""}
      onChange={(e) => props.parentProps.setter(props.path, e.target.value)}
      placeholder={props.placeholder ?? ""}
      readOnly={!props.parentProps.getPermissionWrapper(props.path).write}
      type={props.parentProps.getPermissionType(props.path, 'text')}
      style={!props.parentProps.getPermissionWrapper(props.path).write ? {backgroundColor: colorBG, color} : {color}}
    />
  </div>
}

export const InputNumberGeneric = (props: {
  path: string,
  scale?: number,
  parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design: AppConfigs | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    deleteKey: (path: string) => void;
  }
}) => {

  const val = getKeyValGeneric(props.path, props.parentProps.design, 'number');
  const scale = props.scale ?? 1;


  let color = "#000000";

  return <div>
    <input
      className={styles['value-input-number']}
      value={(val ?? 0) * scale}
      onChange={(e) => props.parentProps.setter(props.path, Number(e.target.value) / scale)}
      readOnly={!props.parentProps.getPermissionWrapper(props.path).write}
      type={props.parentProps.getPermissionType(props.path, 'number')}
      style={!props.parentProps.getPermissionWrapper(props.path).write ? {backgroundColor: colorBG, color} : {color}}
    />
  </div>
}


export const InputDropdownGeneric = (props: {
  path: string,
  valueList: string[],
  allowOther?: boolean,
  readOnly?: boolean,
  type?: string,
  shouldTranslate?: string,
  booleanDefault?: string,
  defaultVal?: string,
  parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design?: AppConfigs | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    deleteKey?: (path: string) => void;
    designTag: string;
    translate: ((e: string) => string);
  }
}) => {

  let currentVal = getKeyValGeneric(props.path, props.parentProps.design, props.booleanDefault);


  if (props.booleanDefault !== undefined && props.booleanDefault !== "") {
    if ([undefined, null].includes(currentVal)) {
      currentVal = props.booleanDefault
    } else {
      currentVal = currentVal.toString();
    }
  }

  if ([null, undefined].includes(currentVal)) {
    currentVal = props.defaultVal ?? "";
    if (currentVal !== "") {
      currentVal = props.shouldTranslate ? props.parentProps.translate(currentVal) : currentVal;
    }
  }

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchVal, setSearchVal] = useState<string>("");
  const [matches, setMatches] = useState<string[]>([]);

  const onMouse = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.button === 0) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    if (
      !(e.button === 1
        && props.parentProps.designTag !== undefined
        && !([undefined, null, ""].includes(currentVal))
      )) {
      return;
    }

    openTab(e, `/generateConfig/${props.parentProps.designTag}/${currentVal}`)
  }

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)){
        if (!props.allowOther) {
          setSearchVal("");
        }
        getMatches(event);
        return;
        //@ts-ignore
      } else if (event.target.tagName!== "LI") {
        getMatches(event);
      }
    }    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, props.valueList]);

  const setSearchValWrapper = (val: string) => {
    setSearchVal(val);
    getMatches(undefined, val);
    if(props.allowOther) {
      props.parentProps.setter(props.path, val);
    }
  }
  const getMatches = (event?: Event, search?: string) => {
    const currentVal = search ?? searchVal;
    if (event) {
      const isActive = event.target === inputRef.current;
      if (currentVal === "" && !isActive) {
        setMatches([]);
        return;
      }
    }
    setMatches([...props.valueList.sort(), 'clear'].filter((val) => val.toLowerCase().includes(currentVal.toLowerCase())));
  }

  const handleChange = (val: string) => {
    setSearchVal("");
    props.parentProps.setter(props.path, val === 'clear' ? '' : val);
    setMatches([]);
  }

  let deleted = false;

  let color = "#000000";

  // getMatches(inputRef);
  // const matches = getMatches()

  return <div className={stylesDropdown['wrapper']} ref={wrapperRef}>
    <input
      className={stylesConfig[`value-input-string`]}
      ref={inputRef}
      value={searchVal}
      placeholder={props.type === 'password' ? '' : currentVal.toString()}
      onChange={(e) => {
        setSearchValWrapper(e.target.value)
      }}
      onKeyDown={(e) => {
        if (e.key !== "Enter") {
          return
        }
        props.parentProps.setter(props.path, searchVal);
        setSearchValWrapper('')
      }}
      onMouseDown={(e) => {
        onMouse(e)
      }}
      onContextMenu={(e) => {
        e.preventDefault()
      }}
      contextMenu={'false'}
      type={props.type}
      readOnly={!props.parentProps.getPermissionWrapper(props.path).write}
      style={props.readOnly ? {backgroundColor: colorBG, color} : {color}}
    />
    {
      matches.length > 0 && !props.readOnly && props.parentProps.getPermissionWrapper(props.path).write
        ?
        <ul className={stylesDropdown['options']}>
          {matches.map((val) => {
            return <li
              className={val === currentVal ? `${stylesDropdown['option']} ${stylesDropdown['option-active']}` : stylesDropdown['option']}
              key={val}
              onClick={() => {
                handleChange(val)
              }}
            >
              {val}
            </li>
          })}
        </ul>
        : null
    }
  </div>
}
