import React, {ChangeEvent, useEffect, useState} from "react";
import { Serial, Design } from "../interfaces/Entry";
import { useDebouncedCallback } from "use-debounce";
import styles from "./selectDesign.module.scss";
import stylesGeneric from "../styles/generics.module.scss";
import { getCookie } from "../methods/cookie";
import { useNavigate } from "react-router-dom";
import {HomeButtonRow} from "../components/HomeButton/HomeButton";
import {Background} from "../components/background/Background";
import {downloadCSV, fetchWrapper} from "../methods/connector";
import {logout} from "../methods/helpers";
import {LoadingOverlay} from "../components/loadingOverlay/loading";


export const SelectDesign = () => {
  const navigate = useNavigate();
  const jwt = getCookie('jwt');
  const [entries, setEntries] = useState<Serial[]>([]);
  const [designs, setDesigns] = useState<string[]>([]);
  const [searchStringSerial, setSearchStringSerial] = useState<string>("");
  const [searchStringDesign, setSearchStringDesign] = useState<string>("");
  const [selectedDropdown, setSelectedDropdown] = useState<string>("");
  const [sortValues, setSortValues] = useState<number[]>([0, 0]);
  const [selectedSerials, setSelectedSerials] = useState<string[]>([]);
  const [isMultisetVisible, setIsMultisetVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchLimit, setSearchLimit] = useState<number>(100);

  const sortIcons = sortValues.map((val): string => {
    switch(val){
      case (0):
        return '-';
      case (1):
        return '▼';
      case (-1):
        return '▲';
      default:
        return '';
    }
  })

  const getColor = (confidence: number, scale: number = 1) => {
    const colorGreen = "#13c713";
    const colorYellow = "#e4ec1c";
    const colorRed = "#e31313";

    let r;
    let g;
    let b;

    // @ts-ignore
    const [rA, gA, bA] = colorRed.match(/\w\w/g).map((c) => parseInt(c, 16));
    // @ts-ignore
    const [rB, gB, bB] = colorYellow.match(/\w\w/g).map((c) => parseInt(c, 16));
    // @ts-ignore
    const [rC, gC, bC] = colorGreen.match(/\w\w/g).map((c) => parseInt(c, 16));

    if (confidence < 0.3) {
      return colorGreen;
    } else if (confidence < 0.4) {
      const amountYellow = Math.min(1, (confidence - 0.3) / 0.1);
      const amountGreen = 1 - amountYellow;

      r = Math.round(rB * amountYellow + rC * amountGreen).toString(16).padStart(2, '0');
      g = Math.round(gB * amountYellow + gC * amountGreen).toString(16).padStart(2, '0');
      b = Math.round(bB * amountYellow + bC * amountGreen).toString(16).padStart(2, '0');
    } else if (confidence < 0.5) {
      const amountRed = Math.min(1, (confidence - 0.4) / 0.1);
      const amountYellow = 1 - amountRed;

      r = Math.round(rA * amountRed + rB * amountYellow).toString(16).padStart(2, '0');
      g = Math.round(gA * amountRed + gB * amountYellow).toString(16).padStart(2, '0');
      b = Math.round(bA * amountRed + bB * amountYellow).toString(16).padStart(2, '0');
    } else {
      return colorRed;
    }

    return '#' + r + g + b;
  }


  const searchStringSerialChange = (newString: string) => {
    setSearchStringSerial(newString);
    debounce(newString, searchStringDesign, searchLimit);
  }

  const searchStringDesignChange = (newString: string) => {
    setSearchStringDesign(newString);
    debounce(searchStringSerial, newString, searchLimit);
  }

  const searchLimitChange = (newLimit: number) => {
    setSearchLimit(newLimit);
    debounce(searchStringSerial, searchStringDesign, newLimit);
  }

  // prevent a search from happening unless the searchString didn't update in 1s
  const debounce = useDebouncedCallback(
    (serial, design, limit) => {
      getData(serial, design, [], limit)
    },
    1000
  )

  const sortCycle = (index: number) => {
    const val = [...sortValues];
    const newVal = Array(val.length).fill(0);
    newVal[index] = ((val[index] + 2) % 3) - 1;
    setSortValues(newVal);
    sortData(newVal);
  }

  const sortOne = (a: Serial, b: Serial, sortOrder: number[]) => {
    if (sortOrder[0] !== 0) {
      const n1 = a.serial.toLocaleLowerCase();
      const n2 = b.serial.toLocaleLowerCase();
      if (n1 < n2) {
        return sortOrder[0]
      } else {
        return -1 * sortOrder[0]
      }
    }
    if (sortOrder[1] !== 0) {
      const n1 = a.designTag.toLocaleLowerCase();
      const n2 = b.designTag.toLocaleLowerCase();
      if (n1 < n2) {
        return sortOrder[1]
      } else {
        return -1 * sortOrder[1]
      }
    }
    // default case
    const n1 = a.serial.toLocaleLowerCase();
    const n2 = b.serial.toLocaleLowerCase();
    if (n1 < n2) {
      return -1
    } else {
      return 1
    }
  }

  const sortData = (sortOrder: number[]) => {
    const data = [...entries].sort((a, b) => sortOne(a, b, sortOrder));
    setEntries(data);
    // data.so
  }

  const getData = async(serial = "", design = "", selected?: string[], limit = searchLimit) => {
    let url = `${process.env.REACT_APP_URL}/serialList`;
    const query = [];

    if (serial !== "") {
      query.push(`serial=${serial}`);
    }
    if (design !== "") {
      query.push(`design=${design}`);
    }
    if (limit > 0) {
      query.push(`limit=${limit}`);
    }
    if (query.length > 0) {
      url += '?'
      url = url + query.join('&')
    }
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'get');
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r: Serial[] = await data.json();
    setEntries(r);
    setSelectedSerials([...(selected ?? selectedSerials)].filter(el => r.map(e => e.serial).includes(el)));
  }


  const handleClick = (serial: string) => {
    setSelectedDropdown(selectedDropdown === serial ? "" : serial);
  }

  const postReference = async(serial: string, isRef: boolean) => {

    let str = isRef ? `Do you want to make scanner ${serial} a reference scanner?`
      : `Do you want to remove scanner ${serial} from the list of reference scanners?`

    if (window.confirm(str)){
      let url = `${process.env.REACT_APP_URL}/serialReference/${serial}`;
      setLoading(true);
      const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({reference: isRef}));
      setLoading(false);
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      getData(searchStringSerial, searchStringDesign)
      // const r = await data.json();
    }
  }


  const postCalibrateQuickly = async(serial: string, calibrateQuickly: boolean) => {

    let str = calibrateQuickly ? `Do you want to set scanner ${serial} a calibrate quickly?`
      : `Do you want to set scanner ${serial} to calibrate gradually?`

    if (window.confirm(str)){
      let url = `${process.env.REACT_APP_URL}/serialCalibrateQuickly/${serial}`;
      setLoading(true);
      const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({calibrateQuickly: calibrateQuickly}));
      setLoading(false);
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      getData(searchStringSerial, searchStringDesign)
      // const r = await data.json();
    }
  }

  const postSelectedOption = async(serial: string, el: string) => {
    const oldEntry = entries.filter(el => el.serial === serial)[0];
    if (oldEntry!== undefined && oldEntry.designTag === el) {
      return;
    }
    if (window.confirm(`Do you want to change the design of scanner ${serial} to design ${el}?`)){
      let url = `${process.env.REACT_APP_URL}/serial/${serial}`;
      setLoading(true);
      const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({designTag: el}));
      setLoading(false);
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      getData(searchStringSerial, searchStringDesign)
      const r = await data.json();
      window.alert(`Successfully assigned design "${el}" to ${r?.['modified'] ?? 0} scanners.`);
    }
    setSelectedDropdown("");
  }

  const postSelectedOptions = async(el: string) => {
    setIsMultisetVisible(false);
    if (selectedSerials.length === 0) {
      return;
    }
    let url = `${process.env.REACT_APP_URL}/serials`;
    if (window.confirm(`Do you want to change the design of ${selectedSerials.length} scanners to design ${el}?`)){
      setLoading(true);
      const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({serials: selectedSerials, designTag: el}));
      setLoading(false);
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      getData(searchStringSerial, searchStringDesign, []);
      const r = await data.json();
      window.alert(`Successfully assigned design "${el}" to ${r?.['modified'] ?? 0} scanners.`);
    }
    setSelectedSerials([]);
  }

  const selectOne = (serial: string) => {
    let selection = [...selectedSerials];
    if (selection.includes(serial)){
      selection = selection.filter(el => el !== serial);
    } else {
      selection.push(serial);
    }
    setSelectedSerials(selection);
  }

  const selectMultiple = (select: boolean = true) => {
    if (select) {
      setSelectedSerials(entries.map(el => el.serial));
    } else {
      setSelectedSerials([]);
    }
  }

  const exportData = () => {
    const resCSV: [string, string][] = [];
    entries.forEach((entry) => {
      resCSV.push([entry.serial, entry.designTag]);
    })
    downloadCSV(resCSV, 'assignments.csv')
  }

  const importData = (e: ChangeEvent<HTMLInputElement>) => {
    if (!(e.target.files)){
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = async el => {
      if (!el?.target?.result || el.target.result instanceof ArrayBuffer) {
        window.alert(`Could not read file.`);
        return;
      }
      try {
        const data = el.target.result;
        if (!data) {
          window.alert(`Could not read file.`);
          return;
        }
        const assignments = data
          .split('\n')
          .filter((row) => row !== "")
          .map((row) => row.replace(/(\r\n|\n|\r)/gm, '').split(','))
        if (!(Array.isArray(assignments)) || assignments.length === 0 || !(Array.isArray(assignments[0]))) {
          window.alert(`Invalid file.`);
          return;
        }
        let url = `${process.env.REACT_APP_URL}/serialsImport`;
        setLoading(true);
        const res = await fetchWrapper(url, jwt, 'post', JSON.stringify({assignments}));
        setLoading(false);
        if (res.status === 401) {
          logout();
          return;
        }
        if (res.status !== 200) {
          window.alert(`Could not import data.`);
          return;
        }
        setSelectedSerials([]);
        setSearchStringSerial(assignments.map(el => el[0]).join(','))
        getData(assignments.map(el => el[0]).join(','), searchStringDesign, [])
      } catch (e) {
        return;
      }
    };
  }

  useEffect(() => {
    const getDesigns = async() => {
      let url = `${process.env.REACT_APP_URL}/designList?name_only=true`;
      const data = await fetchWrapper(url, jwt, 'get');
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      const r: Design[] = await data.json();
      setDesigns(r.map(el => el.designTag).sort());
    }
    // this is toggled twice, as the site is rendered twice
    // this is due to the strict mode being enabled (index.tsx)
    // this causes double-renders for dev-mode only
    if (jwt === '') {
      navigate('/auth');
      return;
    }
    getData();
    getDesigns();
    const handleClickOutside = (event: MouseEvent) => {
      // if a click occurs that is not targeting a Dropdown-component, unselect the current dropdown target
      const t = event.target as HTMLDivElement;
      if (typeof t.className !== 'string'){
        return;
      }
      if (!(t.className.includes("dropdown"))){
        setSelectedDropdown("");
      }
      if (!(t.className.includes("assign"))){
        setIsMultisetVisible(false);
      }

    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className={stylesGeneric['page']}>
      <Background/>
      <HomeButtonRow/>
      <div className={stylesGeneric['content-wrapper']}>

        <div className={stylesGeneric['page-wrapper']}>
          <table className={styles['table']}>
            <thead>
            <tr className={styles['table-row-header']}>
              <th className={styles['header-checkboxes']}>
                <input className={styles['selection-button']} type="button" onClick={() => {
                  selectMultiple(true)
                }} value={"Select all"}>
                </input>
                <input className={styles['selection-button']} type="button" onClick={() => {
                  selectMultiple(false)
                }} value={"Deselect all"}>
                </input>
              </th>
              <th>
                <div className={styles['header-wrapper']}>
                  <input className={styles["search-bar"]} placeholder="Serial" value={searchStringSerial}
                         onChange={(el) => searchStringSerialChange(el.currentTarget.value)}
                        onKeyDown={(e) => {
                          if (e.key !== 'Enter') {return;}
                          let newVal = e.currentTarget.value;
                          if (newVal.startsWith("https://")) {
                            newVal = newVal.split('=').slice(-1)[0];
                            newVal += ',';
                          } else {
                            const match = newVal.match(/\W+/);
                            let character = '';
                            if (match !== null) {
                              character = newVal[match?.index ?? 0];
                              newVal = newVal.split(character).map(el => {
                                if (!el.includes('://')) {
                                  return el;
                                }
                                return el.split('=').slice(-1)[0];
                              }).filter(el => el !== '')
                                .join(',');
                              newVal += character;
                            }
                          }
                          searchStringSerialChange(newVal);
                        }}
                  >
                  </input>
                  <input type="button" className={styles['sort-button']} value={sortIcons[0]}
                         onClick={() => sortCycle(0)}>
                  </input>
                  <input type={"number"} className={styles['limit-input']} value={searchLimit} onChange={(e) => searchLimitChange(Number(e.target.value))}>
                  </input>
                </div>
              </th>
              <th>
                <input className={styles["search-bar"]} placeholder="Design" value={searchStringDesign}
                       onChange={(el) => searchStringDesignChange(el.currentTarget.value)}>
                </input>
                <input type="button" className={styles['sort-button']} value={sortIcons[1]}
                       onClick={() => sortCycle(1)}>
                </input>
              </th>
              <th className={styles['header-checkboxes']}>
                Reference?
              </th>
              <th className={styles['header-checkboxes']}>
                Calibrate quickly?
              </th>
            </tr>
            </thead>

            <tbody>
            {
              entries.map(el => {
                return(<tr className={stylesGeneric['table-row']} key={el.serial} onClick={() => {
                  selectOne(el.serial)
                }}>
                  <td className={styles['row-checkbox']}>
                    <input readOnly type="checkbox" checked={selectedSerials.includes(el.serial)}></input>
                  </td>
                  <td className={stylesGeneric['table-cell']}>
                    {el.serial}
                  </td>
                  <td className={stylesGeneric['table-cell']}>
                    {/* {el.designTag} */}
                    <div
                      className={stylesGeneric['dropdown-wrapper']}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(el.serial)
                      }}
                    >
                      <div className={styles['dropdown-placeholder']}>
                        {el.designTag}
                      </div>
                      {
                        el.serial === selectedDropdown
                          ?
                          <div className={styles['dropdown-options']}>
                            {
                              designs.map(d => {
                                return <div key={d} className={styles['dropdown-option']} onClick={(e) => {
                                  e.stopPropagation();
                                  postSelectedOption(el.serial, d)
                                }}>{d}</div>
                              })
                            }
                          </div>
                          : null
                      }
                    </div>
                    {/* <ReactDropdown options={designs} value={el.designTag} onChange={(option) => {selectOption(el.serial, option.value)}}/> */}
                  </td>
                  <td className={styles['row-checkbox']}
                    onClick={
                      (e) => {
                        e.stopPropagation();
                        const val = !(el.reference)
                        postReference(el.serial, val)
                      }
                    }
                  >
                    <input readOnly type="checkbox" checked={el.reference} ></input>
                    {
                      el.offset.std ?? 0 > 0 ?
                        <p className={styles['offset']} style={{color: getColor(el.offset.confidence + (el.offset.quality ?? 0.5), 2)}}>
                          {/*TODO: Replace the constant 1 in the denominator with a proper value (3 times acceptable std)*/}
                          Hand calibration error: {Math.round((el.offset.confidence + (el.offset.quality ?? 0.5)) * 100) / 100}
                        </p>
                        : null
                    }
                  </td>
                  <td className={styles['row-checkbox']}
                      onClick={
                        (e) => {
                          e.stopPropagation();
                          const val = !(el.calibrateQuickly)
                          postCalibrateQuickly(el.serial, val)
                        }
                      }
                  >
                    <input readOnly type="checkbox" checked={el.calibrateQuickly}></input>

                  </td>
                </tr>)
              })
            }
            </tbody>
          </table>
        </div>
        <div className={styles['assign-bar']}>
          <input className={styles['assign-button']} type="button" onClick={() => {
            setIsMultisetVisible(!isMultisetVisible)
          }} value="Assing to all selected..."/>
          {isMultisetVisible
            ?
              <div className={styles['assign-dropdown']}>
                {
                  designs.map((d) =>
                    <div key={d} className={styles['assign-option']} onClick={() => {postSelectedOptions(d)}}>
                      {d}
                    </div>
                  )
                }
              </div>
            :null
          }
          <input
            type="file"
            className={styles["import-hide"]}
            id={`file-upload`}
            onChange={(e) => {importData(e)}}
          />
          <button
            className={styles['assign-button']}
          >
            <label className={styles["import-button"]} htmlFor={`file-upload`}>
              Import
            </label>
          </button>
          <input className={styles['assign-button']} type="button" onClick={() => {exportData()}} value="Export"/>
        </div>
      </div>
      <LoadingOverlay loading={loading}/>
    </div>
  )
}
